<template>
  <CommonLayout>
    <div class="cabinet">
      <div class="cabinet__inner">
        <button
          class="button-default button-back uni-back"
          @click="$router.go(-1)"
        >
          <span></span>Назад
        </button>
        <div class="cabinet__head">
          <!-- <router-link
            class="button-default button-back cabinet__back"
            :to="{ name: 'rating', params: { type: 'team' } }"
            ><span></span>Назад</router-link
          > -->
          <div class="title">Рейтинг игроков по победам в поединке</div>
        </div>
        <div class="cabinet__body cabinet__body_type_rationg">
          <div class="cabinet__content">
            <div class="uni-multi-switcher">
              <router-link
                :to="{ name: 'usersCoins' }"
                class="uni-multi-switcher__button"
                >GPB-coins</router-link
              >
              <router-link
                :to="{ name: 'usersGratitudes' }"
                class="uni-multi-switcher__button"
              >
                Благодарности
              </router-link>
              <router-link
                :to="{ name: 'usersIdeas' }"
                class="uni-multi-switcher__button"
                >Идеи</router-link
              >
              <router-link
                :to="{ name: 'usersBattles' }"
                class="uni-multi-switcher__button"
              >
                Победы в поединке
              </router-link>
            </div>
            <div class="uni-search">
              <input
                type="text"
                v-model="searchQuery"
                placeholder="Поиск по ФИО"
              />
            </div>
            <div class="cabinet-table">
              <Loading v-if="loading" position="center" />
              <div v-else class="cabinet-table__inner">
                <table v-if="filteredListUser.length === 0">
                  <tbody>
                    <!-- <tr class="disabled empty">
                      <td>Пусто</td>
                    </tr> -->
                  </tbody>
                </table>
                <table v-else>
                  <thead>
                    <tr>
                      <th>ФИО</th>
                      <th width="140px">Достижения</th>
                      <th width="220px">
                        <div style="line-height: 26px">
                          <strong>Побед</strong> / Поединков
                        </div>
                      </th>
                      <th width="100px">Место</th>
                    </tr>
                  </thead>
                  <tbody>
                    <router-link
                      v-for="(item, index) in filteredListUser"
                      :key="index"
                      tag="tr"
                      :class="{ myself: item.id === getProfile.id }"
                      :to="linkToProfile(item.id)"
                    >
                      <td>{{ item.name }}</td>
                      <td>
                        <div v-if="item.achievements.length" class="uni-achive">
                          <router-link
                            tag="div"
                            :to="linkToProfile(item.id, 'achievements')"
                            v-for="(achieve, index) in item.achievements"
                            :key="index"
                            class="uni-achive__item"
                            :style="{
                              'background-image': `url('/static/achieves/${achieve.icon}')`,
                            }"
                          >
                            <Tooltip
                              type="rating"
                              :msg="`
                              <p><strong>${achieve.title}</strong></p>
                              <p>${achieve.description}</p>`"
                            />
                          </router-link>
                        </div>
                        <div v-else class="uni-achive-empty"></div>
                      </td>
                      <td class="cabinet-table__item_custom_color">
                        <strong>{{ item.won }}</strong> из {{ item.overall }}
                      </td>
                      <td>
                        {{ index + 1 }}
                      </td>
                    </router-link>
                  </tbody>
                </table>
                <div class="cabinet-table__more">
                  <button
                    v-if="
                      !searchQuery && limit && limit < getUsersBattles.length
                    "
                    @click="limit = null"
                  >
                    Смотреть все
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="cabinet__sidebar">
            <Dashboard view="ratingGratitudes" />
          </div>
        </div>
      </div>
    </div>
  </CommonLayout>
</template>

<script>
import CommonLayout from "@/layout/CommonLayout.vue";
import Dashboard from "@/components/Dashboard.vue";
import { mapGetters, mapActions } from "vuex";
import Tooltip from "@/components/Tooltip.vue";

export default {
  props: ["teamID"],
  components: {
    CommonLayout,
    Dashboard,
    Tooltip,
  },
  data() {
    return {
      limit: 10,
      searchQuery: "",
    };
  },
  computed: {
    ...mapGetters(["getProfile", "getUsersBattlesStatus", "getUsersBattles"]),
    loading() {
      return this.getUsersBattlesStatus === "loading";
    },
    filteredListUser() {
      const list = this.getUsersBattles;

      if (this.searchQuery) {
        return (
          list
            .filter((item) => {
              return item.name
                .toLowerCase()
                .includes(this.searchQuery.toLowerCase());
            })
            // .sort(sorting)
            .slice(0, this.limitSearch)
        );
      }

      return this.limit
        ? list
            .map((e) => e)
            // .sort(sorting)
            .slice(0, this.limit)
        : list;
    },
  },
  created() {
    if (this.getUsersBattlesStatus !== "success") {
      this.USERS_BATTLES_FETCH();
    }
  },
  methods: {
    ...mapActions(["USERS_BATTLES_FETCH"]),
    linkToProfile(userID, name) {
      if (name) {
        return { name: "achievements", params: { userID } };
      }
      return {
        name: "user",
        params: { userID: userID },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.cabinet-table {
  td,
  th {
    &:nth-child(3) {
      text-align: center;
    }
    &:nth-child(4) {
      text-align: center;
    }
  }
  th {
    strong {
      color: #5effff;
    }
  }
  &__item_custom_color {
    strong {
      color: #5effff;
    }
  }
}

.myself {
  background-image: url("~@/assets/img/icon/star-white.svg");
  background-repeat: no-repeat;
  background-position: 7px calc(50% + 1px);
}
</style>
